<template>
  <div class="withdraw">
    <div class="box">
      <div class="titleBox">
        <p class="titleBoxName">{{ $t.earningsInfo.bankName }}:</p>
        <p class="titleBoxBankCard">{{ bankName }} ({{ bankNumber }})</p>
      </div>
      <div class="state">{{ $t.earningsInfo.banksInfo }}</div>
      <div class="infoBox">
        <p class="infoBoxTitle">{{ $t.earningsInfo.amountWithdraw }}</p>
        <div class="moneyBox">
          <p class="moneyUnit">$</p>
          <input class="money" type="text" v-model="money" />
        </div>
        <section class="handleBox">
          <p class="handleTitle">{{ $t.earningsInfo.withdrawNum(money) }}</p>
        </section>
        <div class="withdrawBt" @click="addShopWithdraw">
          {{ $t.earningsInfo.withdraw }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Withdraw",
  data() {
    return {
      money: 0,
      userId: "",
      shopId: "",
      bankName: "",
      bankNumber: "",
    };
  },
  beforeMount() {
    const { Money } = this.$route.query;
    this.money = Money;
  },
  mounted() {
    this.userId = JSON.parse(localStorage.getItem("auth")).userId;
    this.shopId = JSON.parse(localStorage.getItem("auth")).shopId;
    this.getLoadShopCheckInfo();
  },
  methods: {
    getLoadShopCheckInfo() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      this.$api.shop
        .loadShopCheckInfo()
        .then((res) => {
          const data = res.data;
          this.bankName = data.BankName;
          this.bankNumber = data.BankNumber;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    addShopWithdraw() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        Moneys: this.money,
        ShopId: this.shopId,
        UserId: this.userId,
      };
      this.$api.shop
        .addShopWithdraw(param)
        .then((res) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: res.message,
            status: res.status,
          });
          setTimeout(() => {
            this.$commonEnv.commonAction(this.$commonEnv.actionType.pop);
          }, 500);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.withdraw {
  width: 100%;
  margin-top: 20px;
  .box {
    width: 92%;
    margin: 0 auto;
    @include publicBoxRadio;
    background-color: #ffffff;
    .titleBox {
      @include publicFlex;
      padding: 30px 0 30px 0;
      font-size: 14px;
      color: #000000;
      .titleBoxName {
        margin: 0;
        padding: 0;
        margin-left: 40px;
      }
      .titleBoxBankCard {
        margin: 0;
        padding: 0;
        margin-left: 5px;
      }
    }
    .state {
      padding-left: 40px;
      font-size: 14px;
      color: #a7a7a7;
      padding-bottom: 30px;
      border-bottom: 1px solid #ebebeb;
    }
    .infoBox {
      padding: 30px;
      font-size: 14px;
      color: #000000;
      .infoBoxTitle {
        margin: 0;
        padding: 0;
      }
      .moneyBox {
        @include publicFlex;
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #ebebeb;
        .moneyUnit {
          margin: 0;
          padding: 0;
          font-size: 20px;
          color: #000000;
          font-weight: bold;
          margin-right: 20px;
        }
        .money {
          background: none;
          outline: none;
          border: none;
          font-size: 30px;
          color: #000000;
          font-weight: bold;
        }
      }
      .handleBox {
        padding: 30px 0;
        @include publicFlex;
        .handleTitle {
          margin: 0;
          padding: 0;
          color: #a7a7a7;
        }
        .handleBt {
          margin: 0;
          padding: 0;
          color: #182c91;
          margin-left: 30px;
        }
      }
      .withdrawBt {
        position: relative;
        width: 100%;
        margin: 0 auto;
        border-radius: 3rem;
        padding: 0.8rem 0;
        text-align: center;
        background: linear-gradient(left, #71d283, #01aaa3);
        color: #ffffff;
        font-size: 1rem;
        line-height: 1;
      }
    }
  }
}
</style>
